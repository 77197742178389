import React, { useEffect } from "react";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./ImageAndContentBlock.scss";
import contactImage from "../../../images/contact-image.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

export const ImageAndContentBlock = ({ rows, section, backgroundColour }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className={`image-and-content ${
        section ? "image-and-content--section" : ""
      } image-and-content--${backgroundColour}`}
    >
      <div className="wrap">
        {Object.keys(rows).length > 0 &&
          rows?.map((row, index) => {
            switch (row.contentType) {
              case "image":
                return (
                  <div className="content-row content-row--image" key={index}>
                    <div
                      className="main-image__container"
                      data-aos={index == 1 ? "fade-left" : "fade-right"}
                    >
                      <ImagePass src={row.image} className="main-image" />
                    </div>
                  </div>
                );

              case "text":
                return (
                  <div
                    className={`content-row content-row--text content-row--text--${row.contentTheme}`}
                    key={index}
                  >
                    <RenderContent content={row.content} />
                  </div>
                );

              default:
                return <p key={index}>This content type doesnt exist</p>;
            }
          })}
      </div>
    </div>
  );
};

ImageAndContentBlock.defaultProps = {
  rows: [
    {
      contentType: "text",
      image: null,
      content:
        '<h2>Speak to us about your project</h2>\n<p><strong>Ertech delivers projects as a main contractor to clients across a broad range of sectors throughout Australia.</strong></p>\n<p>The business has demonstrated true scalability delivering projects up to $350 million in our own right, and nearly $1 billion in joint venture. We undertake a range of civil and concrete, geotechnical/environmental, pipeline, marine construction and electrical services using a blend of our own self performing workforce and our valued supply chain partners.</p>\n<p><a class="button--dark" href="https://ertech-staging.distl.dev/contact/">C0ntact Us</a></p>\n',
      contentTheme: "lightGrey",
    },
    { contentType: "image", image: contactImage },
  ],
  section: false,
  backgroundColor: "none",
};
