import React from "react";
import RenderContent from "../../RenderContent";
import { GravityForm, gravityFormExtractor } from "../../gravity-form";
import { ImagePass } from "../../Image/ImagePass";

import "./TwoColumn.scss";
import { useAllGravityForms } from "../../../hooks";

export const TwoColumn = ({
  heading,
  rows,
  columnSpacing,
  wrapWidth,
  fancyBorder,
  columnAlignment,
}) => {
  let gravityFormsData = null;
  return (
    <section className="two-column">
      <div className="wrap">
        <div
          className={`container container--${wrapWidth} fancy-border--${fancyBorder}`}
        >
          {heading && (
            <RenderContent content={heading} className="two-column-header" />
          )}
          <div
            className={`row row-spacing--${columnSpacing} row-alignment--${columnAlignment}`}
          >
            {Object.keys(rows).length > 0 &&
              rows?.map((row, index) => {
                switch (row.contentType) {
                  case "image":
                    return (
                      <div
                        className="content-row content-row--image"
                        key={index}
                      >
                        <div className="main-image__container">
                          <ImagePass src={row.image} className="main-image" />
                        </div>
                      </div>
                    );

                  case "text":
                    return (
                      <div
                        className="content-row content-row--text"
                        key={index}
                      >
                        <RenderContent content={row.content} />
                      </div>
                    );

                  case "form":
                    gravityFormsData = useAllGravityForms(row.form.formId);
                    return (
                      <div
                        className="content-row content-row--form"
                        key={index}
                      >
                        {row.form.formContent && (
                          <div
                            className="content-row content-row--form--content"
                            dangerouslySetInnerHTML={{
                              __html: row.form.formContent,
                            }}
                          />
                        )}
                        <GravityForm
                          formData={gravityFormExtractor(
                            parseFloat(row.form.formId),
                            gravityFormsData
                          )}
                          dataLayerEvent={"form_submit_success"}
                        />
                      </div>
                    );
                  case "iconGrid":
                    return (
                      <div className="content-row content-row--icon-grid">
                        {row.iconGrid.heading && (
                          <h3>{row.iconGrid.heading}</h3>
                        )}
                        <div className="content-row content-row--icon-grid--content">
                          {row.iconGrid.Grid.map((cell, index) => (
                            <div
                              className="content-row content-row--icon-grid--inner"
                              key={`${index}-iconGrid`}
                            >
                              <img src={cell.icon.source_url} />
                              <p className="subheading">{cell.subheading}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    );

                  default:
                    return <p key={index}>This content type doesnt exist</p>;
                }
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
